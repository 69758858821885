<template>
<div class="newcht">    
    <div class="chatdate-main text-center"><div class="chatdate" v-if="msg.displydate" :class="msg.displydate.class">{{msg.displydate.date}}</div></div>
    <li class="clearfix" v-if="msg.sender_id == this.$auth.user.user_id">
        <div class="mesgs-right">
            <div class="message other-message float-right">
                <a :href="msg.image_url" class="cht-img" :class="msg.imgClass" target="_blank">
                    <img :src="msg.image_url">
                </a>
                {{msg.image_url == '' ? msg.message : ''}}
                <span class="message-data-time">{{msg.time}}</span>
            </div>
        </div>
    </li>
    <li class="clearfix" v-else>
        <div class="message my-message">
            <span class="cht-img popup" :class="msg.imgClass" v-if="msg.image_ur" target="_blank" @click="openImg(msg.image_url)">
                <img :src="msg.image_url">
            </span>
            {{msg.image_url == '' ? msg.message : ''}}
            <span class="message-data-time">{{msg.time}}</span>
        </div>
    </li>    
</div>
</template>
<script>
export default {
  name: "ChatContent",
  props: ['msg'],  
  methods: {
    openImg(image){
      this.$emit("imageOpen",image);
    },
  }
} 
</script>